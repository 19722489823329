.page-header {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;

  // Handles header-height since page-header is fixed
  + * {
    margin-top: 70px;
  }
  .bold {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(#e3e8eb, 0.5);
    margin-left: 1.25rem;
  }
  &.inverted {
    .bold {
      background-color: rgba(#e3e8eb, 0.3) !important;
    }
  }
  @media screen and (max-width: $desktopBreakpoint) {
    .label {
      color: white;
    }
    .route {
      color: white;
      background-color: black;
      h3 {
        color: white;
      }
    }
  }
  @media screen and (min-width: $desktopBreakpoint) {
    .label {
      color: black;
    }
    .route {
      color: black;
      background-color: white;
      h3 {
        color: black;
      }
    }
  }
  &.inverted {
    color: white;
    background-color: black;
    .label {
      color: white;
    }
    .route {
      color: white;
      background-color: black;
      h3 {
        color: white;
      }
    }
  }
}
