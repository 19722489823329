@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import 'variables';

$max-text-width: 760px;

div,
span,
button,
input,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  letter-spacing: 0.05em;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
    font-weight: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    letter-spacing: 0.05em;
  }
}

input {
  > {
    font-family: 'Open Sans', sans-serif;
    font-weight: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    letter-spacing: 0.05em;
  }
}

div,
span {
  font-weight: 300;
}

a,
p,
h1,
h2,
h3,
h4 {
  max-width: $max-text-width;
}

p {
  letter-spacing: 0.07;
}

a,
p {
  font-weight: 300;
}

h4 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.bold {
  font-weight: 600;
}

// Font classes
.h4-strong {
  font-weight: 600;
}

ul,
li {
  list-style-type: none;
}

.font-small {
  @include font-small;
}

.font-body {
  @include font-body;
}

.font-large {
  @include font-large;
}

.font-xlarge {
  @include font-xlarge;
}

.font-display {
  @include font-display;
}

.smallcaps {
  @include smallcaps;
}

.font-weight-medium {
  font-weight: 600;
}

// // You'll find the variables in `global.scss`
// $font-sizes: small medium large;

// @each $size in $font-sizes {
//   .font-#{$size} {
//     font-size: var(--font-#{$size});
//     line-height: var(--font-#{$size}-leading);
//   }
// }
