@import './404.scss';
@import './fonts.global';
@import './mixins';
@import './variables';
@import './simpleColumns';
@import './header';
@import './footer';
@import './article-content';
@import './tweaks';
@import './animations';
@import './debug';
@import './themes';
* {
  -webkit-print-color-adjust: exact;
}

html {
  scroll-behavior: smooth;
}

body {
  --inputHeight: 3rem;
  --halfInputHeight: calc(var(--inputHeight) / 2);

  /****************************
    Typography
  ****************************/

  --font-xsmall: 12px;
  --font-xsmall-leading: 1.2;

  --font-small: 14px;
  --font-small-leading: 1.5;

  --font-body: 18px;
  --font-body-leading: 155%;

  --font-large: 25px;
  --font-large-leading: 34px;

  --font-xlarge: 32px;
  --font-xlarge-leading: 44px;

  --font-display: clamp(var(--font-xlarge), 3vw, 60px);
  --font-display-leading: 1.2;

  // Adjustment for non retina screens
  @media (-webkit-device-pixel-ratio: 1) {
    --font-small: 16px;
    --font-body: 20px;
    --font-large: 27px;
    --font-xlarge: 34px;
  }

  /****************************
    Spacing
  ****************************/
  --spacing-xsmall: 8px;
  --spacing-small: 16px;
  --spacing-medium: 30px;
  --spacing-large: 60px;
  --spacing-xlarge: 90px;
  --spacing-section: 120px;

  --header-height: 70px;

  /****************************
    Grid
  ****************************/
  --spacing-gutter: 20px;
  --amount-of-columns: 16;

  /****************************
    Site
  ****************************/
  --max-width: 1440px;
  --gutter: 60px;
  --max-page-width: calc(var(--max-width) - var(--gutter) * 2);
}

html,
body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  font-size: $fontSize;
  overflow-x: hidden;
}

.subaru-container {
  -webkit-font-smoothing: antialiased;
  font-weight: var(--text-font-weight);
  font-family: var(--text-font-family);
  min-height: 100%;
  color: var(--fg1);
  background: var(--bg1);
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

input,
button,
submit {
  border: none;
  font-family: 'Open sans';
  font-size: var(--font-small);
}

button,
submit {
  cursor: pointer;
}

#footer {
  z-index: 10;
}

.asterix {
  font-size: 10px;
  line-height: 15px;
  color: #aaa;
  padding: 0;
  margin: 0;

  em {
    display: inline-block;
  }
}

.price-footer {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.5;
  opacity: 0.4;
  height: 120px;
  width: 100vw;
  max-width: 600px;
  @media screen and (max-width: $desktopBreakpoint) {
    width: unset;
  }
}

.ie {
  display: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      max-width: 640px;
      text-align: center;
    }

    svg {
      position: absolute;
      top: 50px;
      left: 50px;
    }
  }
}

.container {
  @include container;
}

@media screen and (max-width: $desktopBreakpoint) {
  .plug ~ .plug {
    margin-top: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--amount-of-columns), 1fr);
  gap: var(--spacing-gutter);
}

.scroll-offset {
  scroll-margin-top: var(--header-height);
}

@import './print';
