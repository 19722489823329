.dark {
  --theme-color: white;
  --theme-background: black;
  // Button
  --theme-button-background: white;
  --theme-button-color: black;
  // --theme-button-background-hover: transparent;
  // --theme-button-color-hover: white;
  // --theme-button-border: white;
  // --theme-button-border-hover: white;
}

.light {
  --theme-color: black;
  --theme-background: white;
  // Button
  --theme-button-background: black;
  --theme-button-color: white;
  // --theme-button-background-hover: transparent;
  // --theme-button-color-hover: black;
  // --theme-button-border: black;
  // --theme-button-border-hover: black;
}

.theme-blue {
  --theme-color: black;
  // --theme-background: #ededed;
  --theme-background: rgba(227, 232, 235, 0.5);
  // Button
  --theme-button-background: black;
  --theme-button-color: white;
  // --theme-button-background-hover: transparent;
  // --theme-button-color-hover: white;
  // --theme-button-border: white;
  // --theme-button-border-hover: white;
}
