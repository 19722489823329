.SC {
  &__root-container {
    display: flex;
    width: 100vw;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__print {
      width: $printWidth;
      max-width: $maxPrintWidth;
      align-items: flex-start;
    }
  }
  &__print {
    width: $printWidth;
    max-width: $maxPrintWidth;
    align-items: flex-start;
  }
  &__content-container-gutter-wrapper {
    display: flex;
    position: relative;
    width: $pageWidth;
    max-width: 100vw;
    justify-content: center;
    @media screen and (min-width: $desktopBreakpoint) {
      max-width: $pageMaxWidth;
      width: $pageWidth;
    }
    // @media screen and (max-width: $desktopBreakpoint) {
    //     margin-top: 70px;
    // }
    .SC__print & {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      justify-content: flex-start;
    }
  }
  &__content-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100% - $pageGutter * 2;
    .SC__print & {
      width: 100%;
    }
  }
}
