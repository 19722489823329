.debug-control,
.debug-control-print {
  display: block;
  position: fixed;
  bottom: 10px;
  padding: 3px 5px;
  z-index: 999;
  background-color: lightgray;
  &.active {
    background-color: lightgreen;
  }
}

.debug-control {
  left: 10px;
}

.debug-control-print {
  left: 140px;
}

.SC {
  &__root-container {
    &.SC__debug {
      background: $debugColorRootContainer;
      background: repeating-linear-gradient(
        0deg,
        #f0f0f0,
        #f0f0f0 10px,
        #aaaaaa 10px,
        #aaaaaa 20px
      );
    }
  }
  // &__content-container-gutter-wrapper {
  //     background: white;
  // }
  &__content-container {
    //
    .SC__debug & {
      background: $debugColorContentContainer;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background: repeating-linear-gradient(
          90deg,
          rgba(255, 0, 255, 0.3) 0,
          rgba(255, 0, 255, 0.3) $contentColumnWidth,
          rgba(255, 0, 255, 0) $contentColumnWidth,
          rgba(255, 0, 255, 0) $contentColumnWidthPlusSpace
        );
        @media screen and (max-width: $desktopBreakpoint) {
          background: repeating-linear-gradient(
            90deg,
            rgba(255, 0, 255, 0.3) 0,
            rgba(255, 0, 255, 0.3) $contentColumnWidthMobile,
            rgba(255, 0, 255, 0) $contentColumnWidthMobile,
            rgba(255, 0, 255, 0) $contentColumnWidthMobilePlusSpace
          );
        }
      }
    }
  }
}
