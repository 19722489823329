@tailwind base;
@tailwind components;
@tailwind utilities;

strong span {
  font-weight: 700;
}

@layer components {
  .split-container {
    box-sizing: border-box;
    max-width: var(--max-page-width);
    width: 100%;
    padding-left: var(--spacing-gutter);
    padding-right: var(--spacing-gutter);
    margin-left: auto;
    margin-right: auto;
  }

  .center-container {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100vw;
  }

  .smallcaps {
    letter-spacing: 0.2em;
    text-transform: uppercase;
    transform: scale(0.9);
  }

  .font-xsmall {
    font-size: var(--font-xsmall);
    line-height: var(--font-xsmall-leading);
  }

  .font-small {
    font-size: var(--font-small);
    line-height: var(--font-small-leading);
  }

  .font-body {
    font-size: var(--font-body);
    line-height: var(--font-body-leading);
  }

  .font-large {
    font-size: var(--font-large);
    line-height: var(--font-large-leading);
    font-weight: 300;
  }

  .font-xlarge {
    font-size: var(--font-xlarge);
    line-height: var(--font-xlarge-leading);
    font-weight: 300;
  }

  .font-display {
    font-size: var(--font-display);
    line-height: var(--font-display-leading);
  }
}
