#loading {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  background-color: transparent;
  align-self: center;

  &.white {
    width: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 23px;
  }

  &.small {
    width: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 19px;
  }

  &.black {
    border: 5px solid rgba(0, 0, 0, 0.5);
    border-top-color: #000;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

#loading-small {
  display: inline-block;
  width: 50%;
  height: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  background-color: transparent;
  align-self: center;

  &.white {
    width: 15px;
    height: 15px;
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 23px;
  }

  &.small {
    width: 15px;
    height: 15px;
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 19px;
  }

  &.black {
    border: 5px solid rgba(0, 0, 0, 0.5);
    border-top-color: #000;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
