.article-content {
    margin: 30px 0;
    h3 {
        margin-top: 30px;
    }
    /* H4 */
    h3,
    h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    h3:first-of-type {
        margin-top: 0;
    }
    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 155%;
    }
    .block-image {
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        img {
            margin: 0;
        }
    }
    img {
        max-width: 100%;
    }
}